/**
 * costRule
 * 费率
 */

import request from '@/utils/request'

// 查询
export const costRuleSelete = (data) => {
    return request({
        method: 'post',
        url: '/costRule/selete',
        data
    })
}
// 删除
export const costRuleDelete = (data) => {
    return request({
        method: 'post',
        url: '/costRule/delete',
        data
    })
}
// 新增
export const costRuleAdd = (data) => {
    return request({
        method: 'post',
        url: '/costRule/add',
        data
    })
}
// 修改
export const costRuleUpdate = (data) => {
    return request({
        method: 'post',
        url: '/costRule/update',
        data
    })
}
// 查询扣费日志
export const costRuleSeleteDeduction = (data) => {
    return request({
        method: 'post',
        url: '/costRule/seleteDeduction',
        data
    })
}