<template>
  <el-card id="creatCompany">
    <header>
      <h1>公司管理</h1>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>企业ID</span>
            <el-input v-model="id" placeholder="企业ID" size="mini"></el-input>
          </div>
          <div class="left-wrap">
            <span>企业</span>
            <el-input v-model="realnameSearch" placeholder="请输入企业名称" size="mini"></el-input>
          </div>
          <div class="left-wrap">
            <span>账号</span>
            <el-input v-model="usernameSearch" placeholder="请输入账号" size="mini"></el-input>
          </div>
          <div class="left-wrap">
            <span>客服</span>
            <el-input v-model="operators" placeholder="客服人员" size="mini"></el-input>
          </div>
          <!-- <div class="left-wrap">
            <span>套餐月租</span>
            <el-input v-model="seatRateValue" placeholder="套餐月租" size="mini"></el-input>
          </div> -->
          <div class="left-wrap">
            <span>业务员</span>
            <el-select v-model="salesman" placeholder="请选择业务员" size="mini" class="reset-width">
              <el-option v-for="item in salesmanList" :key="item.id" :label="item.lable" size="mini" :value="item.lable"
                class="reset-width">
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap">
            <span>锁定状态</span>
            <el-select v-model="value" placeholder="请选择锁定状态" size="mini">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap">
            <span>客户等级</span>
            <el-select v-model="grade" placeholder="请选择客户等级" size="mini">
              <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap">
            <span>通道类型</span>
            <el-select v-model="type" placeholder="请选择通道类型" size="mini">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>

        </div>
        <div class="right">
          <el-button type="primary" icon="el-icon-search" @click="handleSearch()" size="mini"
            class="searchBtn">查找</el-button>

          <el-button type="primary" icon="el-icon-refresh" @click="reset()" size="mini" class="reset">重置</el-button>

          <el-button type="success" icon="el-icon-mic" @click="add()" class="addSeats" size="mini">添加公司账号</el-button>
        </div>
      </div>
    </header>
    <hr>
    <main>
      <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
        height="600" @row-dblclick="dblclick">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="企业ID" align="center"></el-table-column>
        <el-table-column prop="realname" label="企业名称" width="180" align="center"></el-table-column>
        <el-table-column prop="username" label="账号" width="120" align="center"></el-table-column>
        <!-- <el-table-column
          prop="id"
          label="ID"
          align="center"
        ></el-table-column> -->
        <el-table-column prop="seatCount" sortable label="坐席额度" width="120" align="center"></el-table-column>
        <el-table-column prop="money" sortable label="余额" align="center" width="80"></el-table-column>
        <el-table-column prop="seatMoney" label="套餐余额" width="120" sortable align="center"></el-table-column>
        <el-table-column prop="seatRate" label="套餐月租" width="120" sortable align="center"></el-table-column>
        <el-table-column prop="rate" sortable label="费率" align="center" width="80"></el-table-column>
        <el-table-column prop="salesman" label="业务员" align="center" style="color: red" width="100"></el-table-column>
        <el-table-column prop="operators" label="客服人员" align="center" width="100"></el-table-column>
        <el-table-column prop="grade" label="客户等级" align="center">
          <template v-slot="scope">
            <div>
              {{
                scope.row.grade == 1
                ? "A"
                : scope.row.grade == 2
                  ? "B"
                  : scope.row.grade == 3
                    ? "C"
                    : ""
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="通道类型" align="center" style="color: red">
          <template #default="scope">
            <el-button type="text" disabled size="mini" v-if="scope.row.type == '0'" icon="el-icon-loading"></el-button>
            <span v-if="scope.row.type == 1">移动4</span>
            <span v-if="scope.row.type == 2">电信</span>
            <span v-if="scope.row.type == 3">联通2</span>
            <span v-if="scope.row.type == 4">联通1</span>
            <span v-if="scope.row.type == 5">移动ax</span>
            <span v-if="scope.row.type == 6">联通3</span>
            <span v-if="scope.row.type == 7">联通4</span>
            <span v-if="scope.row.type == 8">H</span>
            <span v-if="scope.row.type == 9">联通5</span>
            <span v-if="scope.row.type == 10">移动3</span>
            <span v-if="scope.row.type == 11">联通6</span>
            <span v-if="scope.row.type == 12">联通7</span>
            <span v-if="scope.row.type == 13">移动5</span>
            <span v-if="scope.row.type == 14">S</span>
            <span v-if="scope.row.type == 15">D</span>
            <span v-if="scope.row.type == 17">sip</span>
            <span v-if="scope.row.type == 18">x</span>
            <span v-if="scope.row.type == 21">2号线</span>
            <span v-if="scope.row.type == 22">3号线</span>
            <span v-if="scope.row.type == 23">新2号线</span>

          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" style="dispaly: flex" width="300" fixed="right">
          <template #default="scope">
            <!-- <el-button type="primary" size="mini" @click="todzwl(scope)"
              >设置地理围栏</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="dzwlUserList(scope.row)"
              >查询启用围栏的员工</el-button
            >
            <el-button type="primary" size="mini" @click="addNoteS(scope)"
              >查询是否开通短信服务</el-button
            >
            <el-button type="primary" size="mini" @click="addRate(scope)"
              >是否开通录音转文字服务</el-button
            >
            <el-button type="primary" size="mini" @click="openskbTc(scope)"
              >开通搜客宝</el-button
            >
            <el-button
              type="danger"
              size="mini"
              @click="lock(scope)"
              v-if="scope.row.status == '2'"
              >解锁</el-button
            > -->

            <el-button type="primary" size="mini" @click="queryCompanyTake(scope)">公司信息记录</el-button>
            <el-button type="primary" size="mini" @click="openEdit(scope)">操作</el-button>
            <el-button type="primary" size="mini" @click="lock(scope)" v-if="scope.row.status == '1'">锁定</el-button>
            <el-button @click="lock(scope)" type="danger" size="mini" v-else style="margin-bottom: 20px">解锁</el-button>
            <!-- <el-button type="danger" size="mini" @click="refund(scope)"
              >发起退款</el-button
            > -->
            <!-- v-if="localParentId == 7" -->
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex"
          :page-size="pageSize" :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal" @size-change="handleSizeChange"
          @current-change="handlePageChange"></el-pagination>
      </div>
    </main>
    <!-- 添加短信服务弹出框 -->
    <el-dialog title="添加短信服务" :visible.sync="addNoteVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="费率">
          <el-input v-model="form.s_rate" placeholder="请输入费率"></el-input>
        </el-form-item>
        <el-form-item label="套餐费率">
          <el-input v-model="form.s_seatRate" placeholder="请输入套餐费率"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="addNoteVisible = false">取 消</el-button>
          <el-button type="primary" @click="addNoteSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑短信服务弹出框 -->
    <el-dialog title="编辑短信服务" :visible.sync="updateNoteVisible" width="30%">
      <el-form ref="form" :model="formss" label-width="100px">
        <el-form-item label="费率">
          <el-input v-model="formss.rate" placeholder="请输入费率"></el-input>
        </el-form-item>
        <el-form-item label="套餐费率">
          <el-input v-model="formss.seatRate" placeholder="请输入套餐费率"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="updateNoteVisible = false">取 消</el-button>
          <el-button type="primary" @click="updateNoteSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 添加弹出框 -->
    <el-dialog title="添加公司账号" :visible.sync="addVisible" top="0%" width="30%">
      <el-form ref="form" :model="form" :rules="formRules" label-width="100px">
        <el-form-item label="账号" prop="username">
          <el-input v-model="form.username" placeholder="请输入账号" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="appid" prop="appid">
          <el-input v-model="form.appid" placeholder="请输入appid" clearable size="mini"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="form.password" placeholder="请输入密码" show-password="true" clearable size="mini"></el-input>
        </el-form-item>
        <el-form-item label="公司名字" prop="realname">
          <!-- <el-input
            v-model="form.realname"
            placeholder="请输入公司名字"
            size="mini"
          ></el-input> -->
          <el-autocomplete class="inline-input" style="width: 100%" v-model="form.realname" size="mini"
            :fetch-suggestions="querySearchRealname" placeholder="请输入公司名字" :trigger-on-focus="false"></el-autocomplete>
        </el-form-item>
        <el-form-item label="签约周期" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入签约周期" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="客服人员" prop="operators">
          <el-input v-model="form.operators" placeholder="请输入运营人员" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="业务员" prop="salesman">
          <el-select v-model="form.salesman" placeholder="请选择业务员" size="mini" class="reset-width">
            <el-option v-for="item in salesmanList" :key="item.id" :label="item.lable" size="mini" :value="item.lable"
              class="reset-width">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订阅类型" prop="whole">
          <el-select v-model="form.whole" placeholder="请选择订阅类型" size="mini">
            <el-option v-for="item in wholeList" :key="item.id" :label="item.lable" size="mini" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="拨打模式" prop="ip">
          <el-select v-model="form.ip" placeholder="请选择拨打模式" size="mini">
            <el-option v-for="item in ipList" :key="item.id" :label="item.lable" size="mini" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="套餐月租" prop="seatRate">
          <el-select v-model="form.seatRate" placeholder="请选择套餐月租" size="mini" filterable>
            <el-option v-for="item in PackageList" :key="item.id" :label="item.lable" size="mini" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="费率" prop="rate">
          <el-select v-model="form.rate" placeholder="请选择费率" size="mini" filterable>
            <el-option v-for="item in RateList" :key="item.value" :label="item.value" size="mini" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="套餐外费率" prop="seatRateW">
          <el-select v-model="form.seatRateW" placeholder="请选择套餐外费率" size="mini" filterable>
            <el-option v-for="item in thaliList" :key="item.value" :label="item.value" size="mini" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="坐席额度" prop="seatCount">
          <el-input-number v-model="form.seatCount" :precision="1" :step="1" :max="99999999" size="mini"
            style="margin-left: 15px"></el-input-number>
        </el-form-item>
        <!-- <el-form-item label="余额">
          <el-input-number
            v-model="form.money"
            :precision="1"
            :step="1"
            :max="99999999"
            size="mini"
            style="margin-left:15px"
          ></el-input-number>
        </el-form-item> -->
        <!-- <el-form-item label="费率" prop="rate">
          <el-input-number v-model="form.rate" :precision="2" :step="0.1" :max="1000" size="mini"
            style="margin-left: 15px"></el-input-number>
        </el-form-item> -->
        <!-- <el-form-item label="套餐月租2" prop="seatRate">
          <el-input-number v-model="form.seatRate" :precision="2" :step="0.1" :max="1000" size="mini"
            style="margin-left: 15px"></el-input-number>
        </el-form-item> -->

        <!-- <el-form-item label="套餐外费率" prop="seatRateW">
          <el-input-number v-model="form.seatRateW" :precision="2" :step="0.1" :max="1000" size="mini"
            style="margin-left: 15px"></el-input-number>
        </el-form-item> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveAdd">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 双击弹出框 -->
    <el-dialog :visible.sync="updateShow" class="dblclick">
      <el-tabs v-model="activeName" @tab-click="fingOneIp">
        <el-tab-pane label="编辑" name="updata">
          <el-form ref="form" :model="forms" :rules="formRules" label-width="100px">
            <el-form-item label="账号">
              <el-input v-model="forms.username" placeholder="请输入账号" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="appid">
              <el-input v-model="forms.appid" placeholder="请输入appid" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model="forms.password" placeholder="请输入密码" show-password="true" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="公司名字">
              <el-input v-model="forms.realname" placeholder="请输入公司名字" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="签约周期" prop="remark">
              <el-input v-model="forms.remark" placeholder="签约周期" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="客服人员" prop="operators">
              <el-input v-model="forms.operators" placeholder="客服人员" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="业务员" prop="salesman">
              <!-- <el-input v-model="forms.salesman" placeholder="业务员" size="mini"></el-input> -->
              <el-select v-model="forms.salesman" placeholder="请选择业务员" size="mini" class="reset-width">
                <el-option v-for="item in salesmanList" :key="item.id" :label="item.lable" size="mini" :value="item.lable"
                  class="reset-width">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="客户等级">
              <el-select v-model="forms.grade" placeholder="请选择客户等级" size="mini" class="reset-width">
                <el-option v-for="item in gradeList" :key="item.id" :label="item.lable" size="mini" :value="item.id"
                  class="reset-width">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="订阅类型">
              <el-select v-model="forms.whole" placeholder="请选择订阅类型" size="mini" class="reset-width">
                <el-option v-for="item in wholeList" :key="item.id" :label="item.lable" size="mini" :value="item.id"
                  class="reset-width">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="拨打模式">
              <el-select v-model="forms.ip" placeholder="请选择拨打模式" size="mini" class="reset-width">
                <el-option v-for="item in ipList" :key="item.id" :label="item.lable" size="mini" :value="item.id"
                  class="reset-width">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="套餐月租" prop="seatRate">
              <el-select v-model="forms.seatRate" placeholder="请选择套餐月租" size="mini" filterable>
                <el-option v-for="item in PackageList" :key="item.id" :label="item.lable" size="mini" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="费率" prop="rate">
              <el-select v-model="forms.rate" placeholder="请选择费率" size="mini" filterable>
                <el-option v-for="item in RateList" :key="item.value" :label="item.value" size="mini" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="套餐外费率" prop="seatRateW">
              <el-select v-model="forms.seatRateW" placeholder="请选择套餐外费率" size="mini" filterable>
                <el-option v-for="item in thaliList" :key="item.value" :label="item.value" size="mini"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="坐席额度">
              <el-input-number v-model="forms.seatCount" :precision="1" :step="1" :max="99999999" size="mini"
                style="margin-left: 15px"></el-input-number>
            </el-form-item>
            <!-- <el-form-item label="费率">
              <el-input-number v-model="forms.rate" :precision="2" :step="0.1" :max="1000" size="mini"
                style="margin-left: 15px"></el-input-number>
            </el-form-item> -->
            <!-- <el-form-item label="套餐月租">
              <el-input-number v-model="forms.seatRate" :precision="2" :step="0.1" :max="1000" size="mini"
                style="margin-left: 15px"></el-input-number>
            </el-form-item> -->

            <!-- <el-form-item label="套餐外费率">
              <el-input-number v-model="forms.seatRateW" :precision="2" :step="0.1" :max="1000" size="mini"
                style="margin-left: 15px"></el-input-number>
            </el-form-item> -->
          </el-form>
          <!-- <p v-if="!editBool" style="font-size: 12px;color: red;">温馨提示:需要等到月底时才能更新该企业的套餐信息</p> -->
          <!-- <el-button type="primary" @click="updateSubmit" style="width: 100%" :disabled="!editBool">确 定</el-button> -->
          <el-button type="primary" @click="updateSubmit" style="width: 100%">确 定</el-button>
        </el-tab-pane>
        <el-tab-pane label="充值" name="cz">
          <el-form ref="form" :model="forms" label-width="100px">
            <el-form-item label="余额">
              <el-input-number v-model="forms.money" :precision="3" :step="1" :max="9999999"
                style="margin-left: 15px"></el-input-number>
            </el-form-item>
            <el-form-item label="附件" prop="workImg">
              <!-- <el-upload ref="uploadImg" :limit="1" class="upload-demo" :headers="uploadHeaders"
                action="api/workOrder/upload" :multiple="false" :on-exceed="handleExceed"
                :before-upload="beforeAvatarUpload" :before-remove="beforeRemove" :on-success="handleAvatarSuccess"
                :file-list="addForm.workImg" list-type="picture">
                <el-button size="mini" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传图片</div>
              </el-upload> -->

              <el-upload ref="uploadImg" :limit="1" class="upload-demo" :headers="uploadHeaders"
                action="api/workOrder/upload" :multiple="false" drag :on-exceed="handleExceed"
                :before-upload="beforeAvatarUpload" :before-remove="beforeRemove" :on-success="handleAvatarSuccess"
                :file-list="addForm.workImg" list-type="picture">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传图片</div>
              </el-upload>
            </el-form-item>
            <el-form-item label="到账账户">
              <!-- 充值类型类型 -->
              <el-radio-group v-model="companyType">
                <el-radio :label="0">暂无</el-radio>
                <el-radio :label="1">商机云</el-radio>
                <el-radio :label="2">星链云</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- 备注 -->
            <el-form-item label="备注">
              <el-input v-model="remarks" type="textarea"></el-input>
            </el-form-item>
          </el-form>
          <el-button @click="mqyspyy" type="primary">充值记录</el-button>
          <el-button type="primary" @click="rechargeSubmit" style="width: 86%" disabled
            v-if="forms.money == null || forms.money == 0">确 定</el-button>
          <el-button type="primary" @click="rechargeSubmit" style="width: 86%" v-else>确 定</el-button>
        </el-tab-pane>
        <el-tab-pane label="套餐余额" name="tcye">
          <el-form ref="form" :model="forms" :rules="formRules" label-width="100px">
            <el-form-item label="套餐余额">
              <el-input-number v-model="forms.seatMoney" :precision="2" size="mini"
                style="margin-left: 15px"></el-input-number>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="balanceSubmit" style="width: 30%">确 定</el-button>
        </el-tab-pane>
        <el-tab-pane label="ip限制" name="ip">
          <el-form ref="form" :model="forms_ip" label-width="100px">
            <!-- 备注 -->
            <el-form-item label="限制省">
              <el-input v-model="forms_ip.provinceLimit" type="text"></el-input>
            </el-form-item>
            <el-form-item label="限制市">
              <el-input v-model="forms_ip.cityLimit" type="text"></el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="ipSubmit" style="width: 30%">确 定</el-button>
        </el-tab-pane>
        <el-tab-pane label="号码费" name="middleNum">
          <el-form ref="form" :model="forms_middleNum" label-width="100px">
            <!-- 备注 -->
            <el-form-item label="单价">
              <!--<el-input v-model="forms_middleNum.price" type="text"></el-input>-->
              <el-input-number v-model="forms_middleNum.price" :precision="0" :step="1" :max="50"
                :min="0"></el-input-number>
            </el-form-item>
            <el-form-item label="中间号">
              <el-input v-model="forms_middleNum.middleNum" type="textarea"></el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="middleNumSubmit" style="width: 30%">确 定</el-button>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- 套餐转移弹框 -->
    <el-dialog title="套餐转移" :visible.sync="PackageTransferShow" v-if="PackageTransferShow" width="30%"
      @closed="addForm = { workImg: [] }, transferType = '退还套餐'">
      <el-form ref="dataAddForm" label-width="80px">
        <p style="margin:10px;">
          余额 <span style="font-weight: bold;color:#409EFF;" v-if="currentCompany.money > 0">{{
            currentCompany.money }}</span>
          <span style="font-weight: bold;color:#f56c6c;" v-else>{{ currentCompany.money }}</span>
        </p>
        <el-form-item label="转移金额">
          <el-input-number v-model="addForm.money" :precision="3" :step="1.0" :min="0">
          </el-input-number>
        </el-form-item>
        <el-form-item label="套餐设置">
          <!-- 到账账户 -->
          <el-radio-group v-model="transferType">
            <el-radio label="退还套餐">退还套餐</el-radio>
            <el-radio label="充值套餐">充值套餐</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 备注 -->
        <el-form-item label="备注">
          <el-input v-model="addForm.remarks" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="PackageTransferShow = false">取 消</el-button>
          <!-- <el-button type="warning" @click="rechargeSubmit('扣款')" :disabled="rechargeBtn">扣款</el-button> -->
          <el-button type="primary" @click="transferSubmit" v-if="addForm.money">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 备注弹出框 -->
    <el-dialog title="编辑开关锁备注" :visible.sync="causeVisble" width="40%" :before-close="handleClose">
      <div>
        <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 10 }" placeholder="请输入内容" v-model="custom_stopReason"
          style="margin-bottom: 25px">
        </el-input>
      </div>
      <el-button type="primary" style="width: 100%" disabled v-if="custom_stopReason == null || custom_stopReason == ''">确
        定</el-button>
      <el-button type="primary" @click="causeSubmit" style="width: 100%" v-else>确 定</el-button>
    </el-dialog>

    <!-- 问题记录弹出框 -->
    <el-dialog title="添加问题记录" :visible.sync="companyTakeShow" top="2%" width="60%">
      <el-form ref="form" :model="forms" label-width="100px">
        <el-form-item label="公司名称">
          <el-input v-model="forms.realname" disabled placeholder="请输入账号" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="新增问题">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" placeholder="请输入问题"
            v-model="currentCompanyTake.question">
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="答案记录">
          <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" :disabled="!currentCompanyTake.question"
            placeholder="请输入内容" v-model="currentCompanyTake.answer">
          </el-input>
        </el-form-item> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="companyTakeShow = false">取 消</el-button>
          <el-button type="primary" @click="updateSubmitCompanyTake">确 定</el-button>
        </span>
      </template>
    </el-dialog>


    <!-- 历史充值记录弹出框 -->
    <div class="historyBox">
      <el-drawer title="历史充值记录" :visible.sync="drawer" :direction="direction">
        <el-table :data="rechargeRecord" style="width: 100%; text-align: center" height="95%">
          <el-table-column prop="money" label="充值后余额"> </el-table-column>
          <el-table-column prop="oldMoney" label="充值前余额"> </el-table-column>
          <el-table-column prop="createTime" label="时间"> </el-table-column>
          <el-table-column prop="img" label="附件">
            <template slot-scope="scope">
              <el-image style="width: 100px; height: 100px" :src="scope.row.img" :preview-src-list="[scope.row.img]">
                <div slot="error" class="image-slot" style="text-align: center;line-height: 100px;">
                  <i class="el-icon-picture-outline"></i>暂无附件
                </div>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="remarks" label="备注信息"> </el-table-column>
        </el-table>
        <el-pagination @size-change="handleRechargeSizeChange" @current-change="handleRechargeCurrentChange"
          :current-page="rechargeParameter.pageIndex" :page-size="rechargeParameter.pageSize"
          layout="total, prev, pager, next" :total="rechargeParameter.pageTotal">
        </el-pagination>
      </el-drawer>
    </div>

    <!-- 查询是否开通短信服务弹出框 -->
    <el-dialog title="查询是否开通短信服务" :visible.sync="addNoteSVisible" width="30%">
      <span>查询是否开通短信服务</span>
      <template #footer>
        <span class="dialog-footer mqyzs">
          <el-button @click="addNoteSVisible = false">取 消</el-button>
          <el-button type="primary" @click="addNoteSearch">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 发起退款弹出框 -->
    <!-- <el-dialog title="发起退款" :visible.sync="refundVisible">
      <el-form ref="addForm" :model="addForm" label-width="100px">
        <div class="refund">
          <el-form-item label="公司名称" prop="workMessage">
            <el-input
              v-model="addForm.workMessage"
              placeholder="请输入公司名称"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="行业类型" prop="askType">
            <el-input
              v-model="addForm.askType"
              placeholder="请输入行业类型"
            ></el-input>
          </el-form-item>
          <el-form-item label="开户时间" prop="beginTime">
            <el-date-picker
              v-model="addForm.beginTime"
              type="datetime"
              disabled
              placeholder="选择开户时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="套餐" prop="endTime">
            <el-input
              v-model="addForm.endTime"
              placeholder="套餐"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="费率" prop="remark">
            <el-input
              v-model="addForm.remark"
              placeholder="费率"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="是否开票" prop="remark1">
            <el-select
              v-model="addForm.remark1"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in TFaskTypeList"
                :key="item.id"
                :label="item.title"
                :value="item.title"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="坐席额度" prop="askTime">
            <el-input
              v-model="addForm.askTime"
              placeholder="坐席额度"
              disabled
            ></el-input>
          </el-form-item>
        </div>
        <div class="balance">
          <el-form-item label="余额" prop="remark4">
            <el-input
              v-model="addForm.remark4"
              placeholder="余额"
              disabled
            ></el-input>
          </el-form-item>
          <el-button
            @click="mqyspyy"
            type="primary"
            style="margin-bottom: 22px; margin-left: 10px"
            >充值记录</el-button
          >
        </div>

        <el-form-item label="退款原因" prop="remark3">
          <el-input
            type="textarea"
            rows="2"
            v-model="addForm.remark3"
            placeholder="请输入退款原因"
          ></el-input>
        </el-form-item>

      </el-form>
      <template #footer>
        <span class="dialog-footer mqyzs">
          <el-button @click="refundVisible = false">取 消</el-button>
          <el-button type="primary" @click="refundSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog> -->

    <!-- 开通录音转文字服务弹出框 -->
    <el-dialog title="开通录音转文字服务" :visible.sync="addRateVisible" class="add-rate">
      <el-form ref="addRateForm" :model="addRateForm" label-width="100px">
        <el-form-item label="企业ID">
          <el-input v-model="addRateForm.companyId" placeholder="请输入企业ID" disabled></el-input>
        </el-form-item>
        <el-form-item label="企业名称">
          <el-input v-model="addRateForm.companyName" placeholder="请输入企业名称" disabled></el-input>
        </el-form-item>
        <el-form-item label="费率">
          <el-input v-model="addRateForm.money" placeholder="请输入费率"></el-input>
        </el-form-item>
        <el-form-item label="公众号推送">
          <el-switch v-model="addRateForm.status" active-color="#13ce66" inactive-color="#ff4949" :active-value="1"
            :inactive-value="2" @change="changeSwitch($event)">
          </el-switch>
        </el-form-item>
        <el-form-item label="超时发送公众号(秒)">
          <el-input :disabled="disabled" v-model="addRateForm.countTime" placeholder="请输入时长"></el-input>
        </el-form-item>
        <el-form-item label="关键词发送公众号">
          <el-input :disabled="disabled" v-model="addRateForm.discourse" placeholder="请输入关键词" type="textarea"
            :rows="2"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addRateVisible = false">取 消</el-button>
          <el-button type="primary" @click="addSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!--  新增套餐 -->
    <el-dialog title="创建套餐" :visible.sync="newTc" width="30%" @close="(tcform = {}), (skbType = null)">
      <el-form ref="tcform" :model="tcform" label-width="100px">
        <!-- <el-form-item label="套餐">
          <el-radio-group v-model="unit">
            <el-radio :label="1">按日开通</el-radio>
            <el-radio :label="2">按月开通</el-radio>
            <el-radio :label="3">按年开通</el-radio>
          </el-radio-group>
        </el-form-item> -->

        <el-form-item label="套餐类型">
          <el-select v-model="skbType" filterable placeholder="请选择套餐">
            <el-option v-for="item in skboptions" :key="item.type" :label="'日查 ' + item.view_contact_num + ' 条套餐'" :value="[
              item.type,
              item.view_contact_num,
              item.clue_volume,
              item.unit,
            ]">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="v.label" v-for="v in tcLabel" :prop="v.modelName" :key="v.id">
          <el-input v-model="tcform[v.modelName]" oninput="value=value.replace(/[^\d^\.]/g,' ')" min="0"
            :placeholder="'请输入' + v.label" :type="v.label == '开通金额(元)' ? 'number' : 'text'"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="newTc = false">取 消</el-button>
          <el-button type="primary" @click="addCompanyTcM">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 搜客宝套餐抽屉 -->
    <el-drawer title="拓客宝套餐" :visible.sync="skbdrawer" :direction="direction">
      <div style="padding: 10px 20px; box-sizing: border-box">
        <div class="skbContent">
          <div style="font-size: 18px; font-weight: 700; margin: 14px 0">
            套餐信息
          </div>
          <div class="tclist" v-for="v in tcList" :key="v.id">
            <div style="flex: 3">
              <span style="font-weight: bold">套餐名</span> &emsp;
              {{ "日查 " + v.viewContactNum + " 条套餐" }}
            </div>
            <div style="flex: 2">
              <span style="font-weight: bold">价格</span> &emsp; {{ v.money }}
            </div>
            <div style="flex: 1">
              <el-popconfirm confirm-button-text="确认" cancel-button-text="点错了" @confirm="deleteCompanyTcM(v.id)"
                icon="el-icon-info" icon-color="red" :title="`确认要删除套餐 日查${v.viewContactNum}条套餐 吗?`">
                <el-link style="color: #3278FE" slot="reference">删除</el-link>
              </el-popconfirm>
              <el-link style="margin-left: 10px; color: #3278FE" @click="editTcNameM(v)">修改</el-link>
            </div>
          </div>
          <div style="margin-left: 3px" v-if="tcList.length == 0">
            暂无套餐信息
          </div>
        </div>
        <el-button style="margin-top: 20px; float: right" type="primary" @click="newTc = true">新增套餐<i
            class="el-icon-circle-plus-outline el-icon--right"></i></el-button>
      </div>
    </el-drawer>

    <!-- 号码检测套餐抽屉 -->
    <el-drawer title="号码检测" :visible.sync="numberdrawer" :direction="direction">
      <div style="padding: 10px 20px; box-sizing: border-box">
        <div class="skbContent" v-if="currentTestInfo.length != 0">
          <div style="font-size: 18px; font-weight: 700; margin: 14px 0">
            号码检测信息
          </div>
          <el-descriptions class="margin-top" :column="1" size="medium" border>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-office-building"></i>

                公司名称
              </template>
              {{ currentTestInfo[0].realname }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-s-data"></i>
                费率
              </template>
              <el-input v-model="currentTestInfo[0].rate" placeholder="请输入费率"></el-input>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 是否开通号码检测 </template>
              <el-tag :size="getTag(currentTestInfo[0])">{{
                getStatus(currentTestInfo[0])
              }}</el-tag>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <el-empty description="暂未开通" v-else></el-empty>
        <el-button style="margin-top: 20px; float: right"
          v-if="currentTestInfo.length != 0 && currentTestInfo[0].status == '1'" type="warning"
          @click="setNumberTest('关闭')">关闭号码检测<i class="el-icon-circle-close el-icon--right"></i></el-button>
        <el-button style="margin-top: 20px; float: right" type="primary" v-else @click="setNumberTest('开通')">开通号码检测<i
            class="el-icon-circle-check el-icon--right"></i></el-button>
        <el-button style="margin-top: 20px; margin-right: 20px; float: right" type="primary"
          v-if="currentTestInfo.length != 0" @click="setNumberTest('费率')">修改费率<i
            class="el-icon-s-tools el-icon--right"></i></el-button>
      </div>
    </el-drawer>

    <!-- 三网权限抽屉 -->
    <el-drawer title="三网权限" :visible.sync="numberdrawer2" :direction="direction">
      <div style="padding: 10px 20px; box-sizing: border-box">
        <el-empty description="暂无三网权限" v-if="!currentTestInfo2"></el-empty>

        <div class="skbContent" v-else>
          <div style="font-size: 18px; font-weight: 700; margin: 14px 0">
            三网权限信息
          </div>
          <el-descriptions class="margin-top" :column="1" size="medium" border>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-office-building"></i>
                公司名称
              </template>
              {{ currentCompany ? currentCompany.realname : '无' }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label"> 是否有权限 </template>
              <el-tag :size="getLimitTag(currentTestInfo2)">{{
                getLimitStatus(currentTestInfo2)
              }}</el-tag>
            </el-descriptions-item>
          </el-descriptions>
        </div>

        <el-button style="margin-top: 20px; float: right" v-if="getIfValue" type="warning"
          @click="setNumberTestLimit('关闭')">关闭权限<i class="el-icon-circle-close el-icon--right"></i></el-button>
        <el-button v-else style="margin-top: 20px; float: right" type="primary" @click="setNumberTestLimit('开通')">开通权限<i
            class="el-icon-circle-check el-icon--right"></i></el-button>
      </div>
    </el-drawer>

    <!--  修改套餐金额 -->
    <el-dialog title="修改套餐金额" :visible.sync="editTcName" width="30%" @close="tcName = null">
      <el-form label-width="100px">
        <el-form-item label="套餐名">
          <div>
            {{
              currentForm
              ? "日查 " + currentForm.viewContactNum + " 条套餐"
              : ""
            }}
          </div>
        </el-form-item>
      </el-form>
      <el-form label-width="100px">
        <el-form-item label="金额">
          <el-input v-model="skbmoney" min="0" :placeholder="'请输入新的套餐金额'" oninput="value=value.replace(/[^\d^\.]/g,' ')"
            type="number"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editTcName = false">取 消</el-button>
          <el-button type="primary" @click="updateCompanyTcM">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 退出电子围栏按钮 -->
    <el-button v-if="openDzwl" class="quit-btn" @click="quitDzwl">退出地理围栏</el-button>
    <!-- 电子围栏 -->
    <dzwl v-if="openDzwl" @getOpenDzwl="getOpenDzwl" :companyName="currentCompany"></dzwl>
    <!-- 电子围栏开通员工抽屉 -->
    <el-drawer title="白名单员工列表(可任意地点拨号)" :visible.sync="dzwldrawer" :direction="direction">
      <div style="padding: 0 20px; box-sizing: border-box">
        <el-button style="margin-bottom: 20px" type="primary" @click="addDzwlUserBox">新增白名单<i
            class="el-icon-circle-plus-outline el-icon--right"></i></el-button>

        <div class="skbContent" style="min-height: 400px">
          <el-table :data="dzwlUserArr" style="width: 100%">
            <el-table-column prop="userName" align="center" label="用户名字" width="250">
            </el-table-column>
            <!-- <el-table-column
              prop="status"
              align="center"
              label="围栏状态"
              width="250"
            >
              <template v-slot="scope">
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div style="margin-right: 10px">
                    {{
                      scope.row.status == 1
                        ? "已启用"
                        : scope.row.status == 2
                        ? "未启用"
                        : "后台数据出错"
                    }}
                  </div>
                  
                  <el-switch
                    v-model="scope.row.status == 2 ? false : true"
                    @change="setdzwlStatus(scope.row)"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                  >
                  </el-switch>
                </div>
              </template>
            </el-table-column> -->
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-popconfirm confirm-button-text="确认" cancel-button-text="点错了" @confirm="delDzwlUser(scope.row)"
                  icon="el-icon-info" icon-color="red" :title="`确认要删除 ${scope.row.userName} 的围栏吗?`">
                  <el-link style="color: #3278FE" slot="reference">删除</el-link>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <!-- <div class="nullTitle">暂无启用员工</div> -->
        </div>
      </div>
    </el-drawer>

    <!--  新增员工 -->
    <el-dialog title="新增白名单" :visible.sync="dzwlNewTc" width="30%" @close="(tcform = {}), (skbType = null)">
      <el-form ref="tcform" :model="tcform" label-width="100px">
        <!-- <el-form-item label="围栏状态">
          <el-radio-group v-model="dzwlSatus">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="2">关闭</el-radio>
          </el-radio-group>
        </el-form-item> -->

        <div style="width"></div>
        <el-form-item label="选择开通员工">
          <el-autocomplete class="inline-input" v-model="oneUser" :fetch-suggestions="querySearch"
            placeholder="请输入完整手机号码查询" @input="getSreachUser" @select="getSreachUserItem"></el-autocomplete>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dzwlNewTc = false">取 消</el-button>
          <el-button type="primary" @click="addDzwlUser">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 操作弹框 -->
    <el-dialog title="公司设置" :visible.sync="editVisible">
      <div class="edit-box">
        <el-button @click="openPackageTransfer(currentScope)" size="medium" style="margin-bottom: 20px">
          套餐转移<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="openskbTc(currentScope)" size="medium" style="margin-bottom: 20px">
          开通拓客宝<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="openNumberTc(currentScope)" size="medium" style="margin-bottom: 20px">
          开通号码检测<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="todzwl(currentScope)" size="medium" style="margin-bottom: 20px">设置地理围栏<i
            class="el-icon-arrow-right el-icon--right"></i></el-button>
        <!-- <el-button size="medium" style="margin-bottom: 20px" @click="queryCompanyTake(currentScope)">
          查看问题记录<i class="el-icon-arrow-right el-icon--right"></i></el-button> -->
        <el-button @click="dzwlUserList(currentScope)" size="medium" style="margin-bottom: 20px">查询启用围栏的员工<i
            class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="addNoteS(currentScope)" size="medium" style="margin-bottom: 20px">查询是否开通短信服务<i
            class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="addRate(currentScope)" size="medium" style="margin-bottom: 20px">是否开通录音转文字服务<i
            class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="openLockDailog(currentScope)" size="medium" style="margin-bottom: 20px">查询锁定日志<i
            class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="openOperationLog(currentScope)" size="medium" style="margin-bottom: 20px">查询操作日志<i
            class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="openRefundLog(currentScope)" size="medium" style="margin-bottom: 20px">查询退费日志<i
            class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="threeNetworkPermissions(currentScope)" size="medium" style="margin-bottom: 20px">查询三网权限<i
            class="el-icon-arrow-right el-icon--right"></i></el-button>
      </div>
    </el-dialog>

    <!-- 锁定日志抽屉 -->
    <el-drawer title="锁定日志列表" :visible.sync="lockdrawer" :direction="direction">
      <div style="padding: 10px 20px; box-sizing: border-box">
        <el-table :data="lockArr" border style="width: 100%">
          <el-table-column prop="userName" label="操作人" align="center">
          </el-table-column>
          <el-table-column prop="createDate" label="操作日期" align="center">
          </el-table-column>
          <el-table-column prop="remarke" label="备注信息" align="center">
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>

    <!-- 操作日志抽屉 -->
    <el-drawer title="操作日志列表" :visible.sync="operationLogdrawer" :direction="direction">
      <div style="padding: 10px 20px; box-sizing: border-box">
        <el-table :data="operationLogArr" border style="width: 100%">
          <el-table-column v-for="(v, i) in operationLogArrLabel" :key="i" :prop="v.name" :label="v.label" align="center">
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>

    <!-- 退费日志抽屉 -->
    <el-drawer title="退费日志列表" :visible.sync="refundLogdrawer" :direction="direction">
      <el-table :data="rechargeRecord" style="width: 100%; text-align: center" height="95%">
        <el-table-column prop="money" label="退费前余额"> </el-table-column>
        <el-table-column prop="oldMoney" label="退费前套餐金额">
        </el-table-column>
        <el-table-column prop="createTime" label="时间"> </el-table-column>
        <el-table-column prop="remarks" label="备注信息"> </el-table-column>
      </el-table>
      <el-pagination @size-change="handleRechargeSizeChange" @current-change="handleRechargeCurrentChange"
        :current-page="rechargeParameter.pageIndex" :page-size="rechargeParameter.pageSize"
        layout="total, prev, pager, next" :total="rechargeParameter.pageTotal">
      </el-pagination>
    </el-drawer>
  </el-card>
</template>

<script>
import { seatPhoneList } from "@/api/seat-phone";
import { costRuleAdd } from "@/api/costRule";
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  userFindlist,
  userUpdate,
  userAdd,
  locking,
  lockJournal,
  userFindAppidbytype,
  userList,
  operationLogJournal,
} from "@/api/user";
import { userSmsUpdate, userSmsAdd, userSmsFindOne } from "@/api/user-sms";
import {
  addDzwlUserApi,
  selectDzwlUserApi,
  deleteDzwlUserApi,
} from "@/api/dzwl";
import {
  customerQuestionList,
  customerQuestionAdd,
  customerQuestionUpdate,
  moneyAndSeatMoney
} from "@/api/company_renew";
import { logUserList } from "@/api/log";
import { approverAdd } from "@/api/approver";
import { add_update, findOne } from "@/api/user-ip-limit";
import {
  queryCompanyTc,
  queryMeal,
  addCompanyTc,
  deleteCompanyTc,
  updateCompanyTc,
} from "@/api/skb";
import {
  list,
  update,
  findOne_limit,
  add_update_limit,
} from "@/api/NnumberDetection";

import {
  add_update_middleNum,
  findOne_middleNum,
} from "@/api/user-middle-num.js";
// 导入电子围栏
import dzwl from "@/views/dzwl/dzwl";
export default {
  components: {
    dzwl,
  },
  data() {
    return {
      PackageTransferShow: false,
      transferType: '退还套餐',
      companyType: 0,
      editBool: false,
      currentCompanyTake: {},
      currentCompany: null,
      timerId: null,
      refundLogArr: [],
      refundLogdrawer: false,
      operationLogArrLabel: [
        {
          name: "userName",
          label: "操作人",
        },
        {
          name: "createDate",
          label: "操作时间",
        },
        {
          name: "remarke",
          label: "操作详细信息",
        },
        {
          name: "executeTimeMills",
          label: "耗时时间",
        },
      ],
      operationLogArr: [],
      seatRateValue: null,
      operationLogdrawer: false,
      lockArr: [],
      lockdrawer: false,
      currentScope: null,
      editVisible: false,
      userId: null,
      selsectDzwlUser: null,
      dzwlSatus: 1,
      seatPhoneListSize: 100,
      currentParentId: null, // 当前公司id
      oneUser: null,
      userOptions: [],
      dzwlNewTc: false,
      dzwlUserArr: [],
      dzwldrawer: false,
      openDzwl: false,
      disabled: true,
      addRateForm: {},
      addRateVisible: false,
      //
      skboptions: [],
      tcLabel: [
        {
          label: "开通金额(元)",
          modelName: "money",
        },
      ],
      skbType: null,
      unit: 3,
      tcform: {},
      newTc: false,
      forms_middleNum: [],
      forms_ip: [],
      localParentId: null,
      imgUrl: "",
      uploadHeaders: {
        "X-Access-Token": localStorage.getItem("TOKEN"),
      }, // 请求头携带token值
      addForm: {
        workImg: [],
      },
      TFaskTypeList: [
        {
          id: 0,
          title: "开发票",
        },
        {
          id: 1,
          title: "不开发票",
        },
      ],
      refundVisible: false,
      custom_stopReason: null,
      custom_status: null,
      activeName: "updata",
      updateNoteVisible: false,
      addNoteVisible: false,
      addNoteSVisible: false,
      rechargeRecord: [],
      rechargeParameter: {
        pageTotal: 0, //页数
        pageIndex: 1, //当前在第几页
        pageSize: 10, //前端规定每页显示的数量
      },
      // 备注弹出框
      causeVisble: false,
      // direction: "ttb",
      skbdrawer: false,
      numberdrawer: false,
      // 三网权限
      numberdrawer2: false,
      tcList: [],
      // 历史充值记录弹出框
      drawer: false,
      direction: "rtl",
      //验证规则
      formRules: {
        remark: [
          { required: true, message: "请输入签约周期", trigger: "blur" },
        ],
        operators: [
          { required: true, message: "请输入客服人员", trigger: "blur" },
        ],
        salesman: [
          { required: true, message: "请输入业务员", trigger: "blur" },
        ],
        seatRate: [
          { required: true, message: "请选择套餐月租", trigger: "blur" },
        ],
        rate: [
          { required: true, message: "请选择费率", trigger: "blur" },
        ],
        seatRateW: [
          { required: true, message: "请选择套餐外费率", trigger: "blur" },
        ]
      },
      id: null,
      realnameSearch: null,
      usernameSearch: null,
      balanceShow: false,
      operators: null,
      salesman: null,
      addVisible: false,
      updateShow: false,
      companyTakeShow: false,
      rechargeShow: false,
      remarks: null,
      value: null,
      grade: null,
      type: null,
      formss: {},
      gradeList: [
        {
          id: 1,
          lable: "A",
        },
        {
          id: 2,
          lable: "B",
        },
        {
          id: 3,
          lable: "C",
        },
      ],
      form: {
        s_rate: "",
        s_seatRate: "",
        rate: "",
        seatRate: 0,
        seatMoney: "",
        username: "",
        password: "",
        appid: "",
        realname: "",
        money: 0,
        ip: "",
        remark: "",
        seatCount: "",
        oldMoney: "",
        operators: "",
        salesman: "",
        whole: "",
        rate: "",
        seatRate: 0,
        seatRateW: "",
        stopReason: "",
        id: null,
      },
      forms: {
        rate: "",
        seatRate: 0,
        seatMoney: "",
        username: "",
        password: "",
        appid: "",
        realname: "",
        money: 0,
        ip: "",
        remark: "",
        seatCount: "",
        oldMoney: "",
        operators: "",
        salesman: "",
        whole: "",
        rate: "",
        seatRate: 0,
        seatRateW: "",
        stopReason: "",
        id: null,
      },
      salesmanList: [
        {
          id: 0,
          lable: "李天豪-总经办",
        },
        {
          id: 0.1,
          lable: "廖薇-总经办",
        },
        {
          id: 1,
          lable: "沈涛-骑士团",
        }, {
          id: 2,
          lable: "杨鹏-骑士团",
        }, {
          id: 3,
          lable: "邓昊燃-骑士团",
        }, {
          id: 4,
          lable: "林靖-骑士团",
        }, {
          id: 5,
          lable: "都文豪-骑士团",
        }, {
          id: 6,
          lable: "施陈龙-骑士团",
        }, {
          id: 7,
          lable: "蒋发-骑士团",
        }, {
          id: 7.1,
          lable: "王芊驹-骑士团",
        }, {
          id: 7.2,
          lable: "陈思-骑士团",
        }, {
          id: 8,
          lable: "刘瑶-飞跃队",
        }, {
          id: 9,
          lable: "谭淼-飞跃队",
        }, {
          id: 10,
          lable: "赵洋-飞跃队",
        }, {
          id: 10.1,
          lable: "简波-飞跃队",
        }, {
          id: 11,
          lable: "曹月-飞跃队",
        }, {
          id: 12,
          lable: "于源琦-飞跃队",
        }, {
          id: 12.1,
          lable: "王昱盛-飞跃队",
        }, {
          id: 12.2,
          lable: "陈睿-飞跃队",
        }, {
          id: 13,
          lable: "paas-芯霓",
        },
        {
          id: 14,
          lable: "上海宏佳",
        },
        {
          id: 15,
          lable: "成都鑫蕴",
        }

      ],
      wholeList: [
        {
          id: 0,
          lable: "非完整订阅",
        },
        // {
        //   id: 1,
        //   lable: "完整订阅",
        // },
        {
          id: 2,
          lable: "个人计费",
        },
        {
          id: 3,
          lable: "个人计费-30天周期",
        },
      ],
      ipList: [
        {
          id: 0,
          lable: "AXB模式",
        },
        {
          id: 1,
          lable: "回拨模式",
        },
      ],
      RateList: [
        {
          value: 0.08
        },
        {
          value: 0.085
        },
        {
          value: 0.09
        },
        {
          value: 0.095
        },
        {
          value: 0.1
        },
        {
          value: 0.105
        },
        {
          value: 0.11
        },
        {
          value: 0.115
        },
        {
          value: 0.12
        },
        {
          value: 0.125
        },
        {
          value: 0.13
        },
        {
          value: 0.135
        },
        {
          value: 0.14
        },
        {
          value: 0.145
        },
        {
          value: 0.15
        },
        {
          value: 0.16
        }
        // ,
        // {
        //   value: 2
        // }
      ],
      thaliList: [
        {
          value: 0.08
        },
        {
          value: 0.085
        },
        {
          value: 0.09
        },
        {
          value: 0.095
        },
        {
          value: 0.1
        },
        {
          value: 0.105
        },
        {
          value: 0.11
        },
        {
          value: 0.115
        },
        {
          value: 0.12
        },
        {
          value: 0.125
        },
        {
          value: 0.13
        },
        {
          value: 0.135
        },
        {
          value: 0.14
        },
        {
          value: 0.145
        },
        {
          value: 0.15
        },
        {
          value: 0.155
        },
        {
          value: 0.16
        }
      ],
      PackageList: [
        {
          id: 0,
          value: 150,
          lable: '150套餐',
        },
        {
          id: 1,
          value: 200,
          lable: '200套餐',
        },
        {
          id: 2,
          value: 300,
          lable: '300套餐',
        },
        {
          id: 3,
          value: 360,
          lable: '360套餐',
        },
        {
          id: 4,
          value: 399,
          lable: '399套餐',
        },
        {
          id: 5,
          value: 100,
          lable: '100套餐',
        },
        {
          id: 6,
          value: 1,
          lable: '1测试',
        }
      ],
      currentTestInfo: [],
      currentTestInfo2: [],
      seatMoney: null,
      password: null,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
      realname: null,
      channelType: "", //通道类型
      mid: "",
      options: [
        {
          value: "1",
          label: "未锁定",
        },
        {
          value: "2",
          label: "已锁定",
        },
      ],
      options1: [
        {
          value: "1",
          label: "A",
        },
        {
          value: "2",
          label: "B",
        },
        {
          value: "3",
          label: "C",
        },
      ],
      options2: [
        {
          value: "1",
          label: "移动4",
        },
        {
          value: "2",
          label: "电信",
        },
        {
          value: "3",
          label: "联通2",
        },
        {
          value: "4",
          label: "联通1",
        },
        {
          value: "5",
          label: "移动ax",
        },
        {
          value: "6",
          label: "联通3",
        },
        {
          value: "7",
          label: "联通4",
        },
        {
          value: "8",
          label: "H",
        },
        {
          value: "9",
          label: "联通5",
        },
        {
          value: "10",
          label: "移动3",
        },
        {
          value: "11",
          label: "联通6",
        },
        {
          value: "12",
          label: "联通7",
        },
        {
          value: "13",
          label: "移动5",
        },
        {
          value: "14",
          label: "S",
        },
        {
          value: "15",
          label: "D",
        },
        {
          value: "17",
          label: "sip",
        },
        {
          value: "18",
          label: "x",
        },

        {
          type: 23,
          label: "新2号线",
        }
      ],
      value: "",
      causeData: "", //备注内容显示
      currentCompany: null,
      currentSkbRow: null,
      currentForm: {},
      editTcName: false,
      skbmoney: 0,
    };
  },
  created() {
    // if (this.$route.query.seat_rate) {
    //   this.seatRateValue = this.$route.query.seat_rate
    // }
    this.isMonthEnd()
    this.localParentId = localStorage.getItem("parentId");
    this.realname = localStorage.getItem("realname");
    this.getList();
    this.queryMealInfo();
  },
  computed: {
    getIfValue() {
      if (!this.currentTestInfo2) {
        return false
      } else {
        if (this.currentTestInfo2.ydPhoneLimit == 1) {
          return true
        } else {
          return false
        }
      }

    }
  },
  mounted() { },
  methods: {
    // 套餐转移
    async transferSubmit() {
      let params = {
        id: this.currentCompany.id,
        old_money: this.currentCompany.money,
        appid: this.currentCompany.appid,
        name: this.currentCompany.name,
        userId: this.currentCompany.userId,
        ...this.addForm
      };
      if (this.transferType == '充值套餐') {
        params.money = params.money * -1
      }
      this.PackageTransferShow = false;

      console.log("参数", params);
      let res = await moneyAndSeatMoney(params);
      console.log("返回参数", res);
      if (res.data.statusCode == "00000") {
        Message.success(res.data.message);
        this.getList();
      } else {
        this.$alert(res.data.message, '', {
          confirmButtonText: '确定',
          callback: action => {
          }
        })
      }
      // moneyAndSeatMoney
      // axios
      //   .post("api/user/update-moneyAndSeatMoney", params)
      //   .then((res) => {
      //     if (res.data.statusCode == "00000") {
      //       this.getList();
      //       this.PackageTransferShow = false
      //       this.$message.success(res.data.message);
      //     } else {
      //       this.$alert(res.data.message, '', {
      //         confirmButtonText: '确定',
      //         callback: action => {
      //         }
      //       })
      //     }

      //   })
      //   .catch((err) => {
      //   })
    },
    openPackageTransfer(row) {
      this.editVisible = false;
      if (row) {
        this.currentSkbRow = row;
        this.currentCompany = row;
      }
      this.PackageTransferShow = true
      console.log("当前企业信息", this.currentCompany);
    },
    beforeAvatarUpload(file) {
      const isImage = file.type.startsWith('image/');
      // const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImage) {
        this.$message.error('上传附件只能是图片格式!');
      }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      // return isImage && isLt2M;
      return isImage;
    },
    beforeRemove(file, fileList) {
      // return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleExceed(files, fileList) {
      Message.warning(`最多上传一张图片附件`);
    },
    // 月底隐藏删除按钮
    isMonthEnd() {
      let date = new Date();
      let currentMonth = date.getMonth();
      let nextMonth = ++currentMonth;
      let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
      let oneDay = 1000 * 60 * 60 * 24;
      let lastTime = new Date(nextMonthFirstDay - oneDay);
      let day = lastTime.getDate();
      // console.log("当月最后一天", day);
      let currentDay = date.getDate(); // 获取当前日(1-31)
      // console.log("当天", currentDay);
      if (currentDay == day) {
        // return (this.lastDay = 1);
        this.editBool = true
      }
    },
    // 提交问题记录
    async updateSubmitCompanyTake() {
      let params;
      let res;
      if (!!this.currentCompanyTake) {
        params = {
          parentId: this.forms.id,
          ...this.currentCompanyTake,
        };
        res = await customerQuestionAdd(params);
      } else {
        params = { id: this.currentCompanyTake.name, answer: this.currentCompanyTake.question };
        res = await customerQuestionUpdate(params);
      }
      if (res.data.statusCode == "00000") {
        Message.success(res.data.message);
        this.companyTakeShow = false;
        this.currentCompanyTake = {}
      } else {
        Message.error(res.data.message);
      }
    },
    // 查看问题记录
    async queryCompanyTake(row) {
      this.forms = row.row;
      console.log("row", row);
      // let params = {
      //   parentId: row.id,
      // };
      // let res = await customerQuestionList(params);
      // console.log("resasdasd", res);
      // if (res.data.data.length) {
      //   this.currentCompanyTake = res.data.data[0];
      // } else {
      //   this.currentCompanyTake = {}
      // }
      // console.log("currentCompanyTake", this.currentCompanyTake);

      this.companyTakeShow = true;
    },
    // 获得号码标签样式
    getTag(v) {
      if (v.status == 1) {
        return "success";
      } else if (v.status == 0) {
        return "info";
      }
    },
    // 获得号码状态
    getStatus(v) {
      if (v.status == 1) {
        return "已开通";
      } else if (v.status == 0) {
        return "未开通";
      }
    },
    // 获得三网权限标签样式
    getLimitTag(v) {
      if (v.ydPhoneLimit == 1) {
        return "success";
      } else {
        return "info";
      }
    },
    getLimitStatus(v) {
      if (v.ydPhoneLimit == 1) {
        return "已开通";
      } else {
        return "未开通";
      }
    },
    // 打开退费日志弹框
    openRefundLog(row) {
      this.editVisible = false;
      let params = {
        id: row.id,
        page: this.rechargeParameter.pageIndex,
        remarks: "退费",
      };
      let res = logUserList(params);
      res.then((res) => {
        let data = res.data.data;
        this.rechargeRecord = data;
        this.rechargeParameter.pageTotal = res.data.total;
        this.refundLogdrawer = true;
      });
    },
    // 打开操作日志弹框
    async openOperationLog(row) {
      this.editVisible = false;
      let params = {
        companyId: row.id,
      };

      const loading = this.$loading({
        lock: true,
        text: "努力加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await operationLogJournal(params);
      console.log("公司操作信息", res);

      this.operationLogArr = res.data.data;
      this.operationLogdrawer = true;
      loading.close();
    },
    // 打开锁定日志弹框
    //   this.currentScope = scope.row;
    async openLockDailog(row) {
      this.editVisible = false;
      let params = {
        companyId: row.id,
      };

      let res = await lockJournal(params);

      this.lockArr = res.data.data;

      this.lockdrawer = true;
    },
    // 打开操作弹框
    openEdit(scope) {
      this.currentScope = scope.row;
      console.log("当前scope", this.currentScope);
      this.editVisible = true;
    },
    // 地理围栏关闭(组件控制)
    getOpenDzwl(bool) {
      this.getList();
      this.openDzwl = bool;
    },
    // 删除地理围栏的员工
    async delDzwlUser(row) {
      let params = {
        id: row.id,
      };
      let res = await deleteDzwlUserApi(params);
      console.log("row", res);
      if (res.data.statusCode == "00000") {
        this.dzwlUserList(this.currentCompany);
        Message.success(res.data.message);
      } else {
        Message.error(res.data.message);
      }
    },
    // 新增地理围栏的员工
    addDzwlUserBox() {
      this.getUserList();
      this.dzwlNewTc = true;
    },
    // 确认新增员工
    async addDzwlUser() {
      if (!this.selsectDzwlUser) {
        Message.warning("请选择弹出列表的员工再确认");
        return false;
      }
      let params = {
        companyId: this.currentCompany.id,
        userName: this.selsectDzwlUser.realname,
        userId: this.selsectDzwlUser.user_id,
        // status: this.dzwlSatus,
      };

      let res = await addDzwlUserApi(params);
      if (res.data.statusCode == "00000") {
        this.dzwlUserList(this.currentCompany);
        this.dzwlNewTc = false;
        Message.success(res.data.message);
      } else {
        Message.error(res.data.message);
      }

      console.log("新增员工", res);
    },

    // 新建企业时，公司名字搜索有无重复
    querySearchRealname(queryString, cb) {
      // var results = null
      // 调用 callback 返回建议列表的数据
      //如果接到订单就再等3分钟
      if (this.timerId) {
        window.clearTimeout(this.timerId);
      }
      //3分钟没有接到订单就直接配送
      this.timerId = setTimeout(async () => {
        let ObjArr = await this.getScreenResult(queryString);
        console.log("打印了", ObjArr);
        cb(ObjArr);
        this.timerId = null;
      }, 1500);

      // cb(results);
    },
    async getScreenResult(realname) {
      let params = {
        page: 1,
        size: 500,
        parentId: 0,
        realname,
      };
      let res = await userFindlist(params);
      // console.log("res666",res.data.data);
      return res.data.data.map((v) => {
        v.value = v.realname;
        return v;
      });
    },
    // 搜索建议
    querySearch(queryString, cb) {
      var restaurants = this.userOptions;
      var results;
      // 输入的值 queryString
      if (queryString) {
        results = restaurants.filter(this.createFilter(queryString));
      } else {
        results = restaurants;
      }
      // 调用 callback 返回建议列表的数据
      console.log("筛选出来的值", results);
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        console.log();
        return (
          restaurant.username
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    // 获取选中的当前员工
    getSreachUserItem(item) {
      this.selsectDzwlUser = item;
      console.log("奥术大师多", item);
    },
    // 搜索员工
    getSreachUser() {
      console.log("搜索员工", this.oneUser);
      this.getUserList(this.oneUser);
    },
    // 获取所员工信息
    async getUserList(name) {
      let params = {
        userId: this.currentCompany.id,
      };
      console.log("name", name);
      if (name) {
        params.username = name;
      }
      let res = await userList(params);
      console.log("搜索员工", res);
      this.userOptions = res.data.data.map((v) => {
        v.value = v.realname;
        v.address = v.user_id;
        return v;
      });
    },
    // 设置围栏状态
    setdzwlStatus(row) {
      row.status == 2 ? (row.status = 1) : (row.status = 2);
      console.log("设置围栏状态");
    },
    // 打开当前公司下启用员工的弹框
    async dzwlUserList(row) {
      this.editVisible = false;

      console.log("打开当前公司信息", row);
      // this.currentParentId = row.id;
      this.currentCompany = row;
      let params = {
        companyId: this.currentCompany.id,
      };
      let res = await selectDzwlUserApi(params);
      if (res.data.statusCode == "00000") {
        this.dzwlUserArr = res.data.data;
      } else {
        Message.error(res.data.message);
      }
      // console.log("打开当前公司下启用员工的弹框", res);
      this.dzwldrawer = true;
    },
    // 退出地理围栏
    quitDzwl() {
      this.openDzwl = false;
    },
    // 跳转到地理围栏
    todzwl(row) {
      this.editVisible = false;
      console.log("跳转到地理围栏", row);
      this.currentCompany = row;
      this.openDzwl = true;
    },
    // 新增费率
    changeSwitch(callback) {
      if (callback == "1") {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    addRate(row) {
      this.editVisible = false;

      console.log(345434565446, row);
      this.addRateVisible = true;
      this.addRateForm = {};
      this.addRateForm.companyId = row.id;
      this.addRateForm.companyName = row.realname;
    },
    addSubmit() {
      if (this.addRateForm.money) {
        let params = {
          companyId: this.addRateForm.companyId,
          companyName: this.addRateForm.companyName,
          money: this.addRateForm.money,
          status: this.addRateForm.status,
          countTime: this.addRateForm.countTime,
          discourse: this.addRateForm.discourse,
        };
        let res = costRuleAdd(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data);
          } else {
            Message.error(res.data);
          }
          this.addRateVisible = false;
          // this.getList();
        });
      } else {
        Message.warning("请输入费率!");
      }
    },

    // 修改公司套餐金额
    async updateCompanyTcM() {
      let params = {
        id: this.currentForm.id,
        money: this.skbmoney,
      };
      let res = await updateCompanyTc(params);
      if (res.data.statusCode == "00000") {
        Message.success(res.data.message);
      } else {
        Message.error(res.data.message);
      }
      this.openskbTc();
      this.editTcName = false;
    },
    // 打开搜客宝套餐修改弹框
    editTcNameM(v) {
      this.currentForm = v;
      this.editTcName = true;
    },
    // 删除公司套餐金额
    async deleteCompanyTcM(id) {
      let res = await deleteCompanyTc({ id });
      if (res.data.statusCode == "00000") {
        Message.success(res.data.message);
      } else {
        Message.error(res.data.message);
      }
      this.openskbTc();
    },
    // 查询套餐信息
    async queryMealInfo() {
      let params = {};
      let res = await queryMeal(params);
      if (res.data.statusCode == "00000") {
        console.log("查询套餐信息", res.data);
        this.skboptions = res.data.data.meals;
      }
    },
    // 新增公司套餐金额
    async addCompanyTcM() {
      console.log("this.skbType", this.skbType);
      if (!this.skbType) {
        Message.error("未选择套餐类型");
        return false;
      }
      let params = {
        companyId: this.currentSkbRow.id,
        companyName: this.currentSkbRow.realname,
        unit: this.skbType[3],
        type: this.skbType[0],
        viewContactNum: this.skbType[1],
        clueVolume: this.skbType[2],
        ...this.tcform,
      };
      let res = await addCompanyTc(params);
      if (res.data.statusCode == "00000") {
        Message.success(res.data.message);
        this.openskbTc();
        this.newTc = false;
      } else {
        Message.error(res.data.message);
      }
    },
    // 打开搜客宝套餐弹框
    async openskbTc(row) {
      this.editVisible = false;
      if (row) {
        this.currentSkbRow = row;
        this.currentCompany = row;
      }

      let params = {
        page: "1",
        size: "100",
        companyName: this.currentSkbRow.realname,
      };

      let res = await queryCompanyTc(params);
      console.log("公司信息", res);

      this.tcList = res.data.data;

      this.skbdrawer = true;
    },
    // 打开三网权限抽屉
    async threeNetworkPermissions(row) {
      this.editVisible = false;
      if (row) {
        this.currentSkbRow = row;
        this.currentCompany = row;
      }

      console.log("当前公司信息", row);
      let params = {
        userId: row.id,
      };
      let res = await findOne_limit(params);
      console.log("打开开通号码检测弹框", res.data.data);
      this.currentTestInfo2 = res.data.data;

      this.numberdrawer2 = true;
    },
    // 打开开通号码检测弹框
    async openNumberTc(row) {
      this.editVisible = false;
      if (row) {
        this.currentSkbRow = row;
        this.currentCompany = row;
      }

      console.log("当前公司信息", row);
      let params = {
        parentId: row.id,
      };
      let res = await list(params);
      console.log("打开开通号码检测弹框", res.data.data);
      this.currentTestInfo = res.data.data;

      this.numberdrawer = true;
    },
    // 修改三网权限
    async setNumberTestLimit(str) {
      const loading = await this.$loading({
        lock: true,
        text: `${str}三网权限中,请稍等...`,
      });


      let params = {
        userId: this.currentCompany.id,
        // id: this.currentTestInfo2.id,
      };
      if (str == "开通") {
        params.ydPhoneLimit = 1;
      } else {
        params.ydPhoneLimit = 0;
      }

      let res = await add_update_limit(params);
      console.log("this.currentTestInfo2", res);
      if (res.data.statusCode == "00000") {
        this.threeNetworkPermissions(this.currentSkbRow)
        loading.close();
        Message.success(res.data.message);
      } else {
        loading.close();
        Message.error(res.data.message);
      }
    },
    // 编辑号码检测
    async setNumberTest(str) {
      const loading = await this.$loading({
        lock: true,
        text: "编辑号码检测中,请稍等...",
      });
      let params;
      if (this.currentTestInfo.length == 0) {
        params = {
          parentId: this.currentCompany.id,
          rate: 0,
          status: 1,
        };
      } else {
        params = {
          parentId: this.currentTestInfo[0].parentId,
          rate: this.currentTestInfo[0].rate,
          status: this.currentTestInfo[0].status,
        };
      }

      if (str == "开通") {
        if (this.currentTestInfo.length != 0) {
          this.currentTestInfo[0].status = "1";
        }
      }
      if (str == "关闭") {
        this.currentTestInfo[0].status = "0";
      }

      let res = await update(params);
      if (res.data.statusCode == "00000") {
        if (this.currentTestInfo.length == 0) {
          let params = {
            parentId: this.currentCompany.id,
          };
          let res = await list(params);
          this.currentTestInfo = res.data.data;
        }
        loading.close();
        Message.success(res.data.message);
      } else {
        loading.close();
        Message.error(res.data.message);
      }
    },

    //修改中间号扣费
    middleNumSubmit() {
      console.log("forms_ip_id", this.forms_middleNum.id);
      let params = {
        id: this.forms_middleNum.id,
        parentId: this.forms.id,
        price: this.forms_middleNum.price,
        middleNum: this.forms_middleNum.middleNum,
      };
      let res = add_update_middleNum(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.updateShow = false;
          this.getList();
        } else {
          Message.error(res.data.message);
          this.updateShow = false;
          this.getList();
        }
        this.activeName = "updata";
      });
    },
    //修改ip限制
    ipSubmit() {
      console.log("forms_ip_id", this.forms_ip.id);
      let params = {
        id: this.forms_ip.id,
        parentId: this.forms.id,
        provinceLimit: this.forms_ip.provinceLimit,
        cityLimit: this.forms_ip.cityLimit,
      };
      let res = add_update(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.updateShow = false;
          this.getList();
        } else {
          Message.error(res.data.message);
          this.updateShow = false;
          this.getList();
        }
        this.activeName = "updata";
      });
    },
    //查询单个公司ip限制
    fingOneIp(e) {
      if (3 == e.index) {
        this.forms_ip = [];
        console.log("ip限制");

        let params = {
          parentId: this.forms.id,
        };
        let res = findOne(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
            this.forms_ip = res.data.data;
          } else {
            Message.error(res.data.message);
            this.forms_ip = [];
          }
        });
      } else if (4 == e.index) {
        this.forms_middleNum = [];
        console.log("中间号");

        let params = {
          parentId: this.forms.id,
        };
        let res = findOne_middleNum(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
            this.forms_middleNum = res.data.data;
          } else {
            Message.error(res.data.message);
            this.forms_middleNum = [];
          }
        });
      }
    },
    // 图片上传成功时
    handleAvatarSuccess(res, file, fileList) {
      this.imgUrl = ''
      if (fileList) {
        this.imgUrl = fileList.map((v) => {
          return v.response
        });
      }
      this.imgUrl ? this.imgUrl = this.imgUrl.join() : ''

      // let imageUrl = URL.createObjectURL(file.raw);
      // console.log("res", res);
      // console.log("file", file);
      // console.log("addForm.workImg", this.imgUrl);

      // console.log("imageUrl", imageUrl);
    },

    // 退费按钮
    refund(row) {
      console.log(1234, row.row);
      this.addForm.remark4 = row.row.money;
      this.addForm.remark = row.row.rate;
      this.addForm.askTime = row.row.seatCount;
      this.addForm.endTime = row.row.seatMoney;
      this.addForm.workMessage = row.row.realname;
      this.returnPremiumId = row.row.id;
      this.refundVisible = true;
      this.addForm.beginTime = row.row.createTime;
    },
    // 退费确认
    refundSubmit() {
      let params = {
        workOrderType: 5,
        returnPremiumId: this.returnPremiumId,
        workMessage: this.addForm.workMessage, // 公司名称
        askType: this.addForm.askType, // 行业类型
        endTime: this.addForm.endTime, // 套餐
        remark: this.addForm.remark, // 费率
        remark1: this.addForm.remark1, // 是否开票
        askTime: this.addForm.askTime, // 坐席额度
        remark3: this.addForm.remark3, // 退款原因
        remark4: this.addForm.remark4, // 余额
        beginTime: this.addForm.beginTime
          ? this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.addForm.beginTime)
          : null, // 开户时间
      };
      console.log(2345567, params);
      // let res = approverAdd(params);
      // res.then((res) => {
      //   let code = res.data.statusCode;
      //   if (code == "00000") {
      //     Message.success(res.data);
      //   } else {
      //     Message.error("该功能暂未开放");
      //   }
      // });
    },
    // 搜索
    handleSearch() {
      this.page = 1;
      this.getList();
    },
    // 备注关闭回调
    handleClose(done) {
      this.causeVisble = false;
      this.custom_stopReason = null;
      // this.$confirm("请填写好备注信息点击确认进行关闭哦")
      //   .then((_) => {
      //     // done();
      //   })
      //   .catch((_) => {});
    },
    // 双击事件
    dblclick(row) {
      this.$confirm(`确认要开始修改企业 <${row.realname}> 的设置吗?`, '提示', {
        confirmButtonText: '开始修改',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.updateShow = true;
        this.forms = { ...row };
        console.log("this.forms", this.forms);
        this.forms.money = null;
        this.forms.password = null;
        this.forms_ip = [];
        this.activeName = "updata";
      }).catch(() => {
        Message.info('已取消');
      });

    },
    // 套餐余额确认
    balanceSubmit() {
      let params = {
        seatMoney: this.forms.seatMoney,
        seatMoney2: this.forms.seatMoney2,
        id: this.forms.id,
      };
      let res = userUpdate(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.updateShow = false;
          this.getList();
        } else {
          Message.error(res.data.message);
          this.updateShow = false;
          this.getList();
        }
      });
      this.balanceShow = false;
    },
    // 套餐余额
    balance(row) {
      this.balanceShow = true;
      this.forms.seatMoney = row.row.seatMoney;
      this.forms.seatMoney2 = row.row.seatMoney;
      this.forms.id = row.row.id;
    },
    // 编辑短信服务
    updateNoteSubmit(row) {
      let params = {
        id: this.mid,
        rate: this.formss.rate,
        seatRate: this.formss.seatRate,
      };
      let res = userSmsUpdate(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.updateNoteVisible = false;
      });
    },
    // 确认添加短信服务
    addNoteSubmit() {
      let params = {
        parentId: this.form.id,
        rate: this.form.s_rate,
        seatRate: this.form.s_seatRate,
      };
      let res = userSmsAdd(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.addNoteVisible = false;
      });
    },
    // 查询是否开通短信服务
    addNoteS(row) {
      this.editVisible = false;

      this.form = { ...row };
      this.addNoteSVisible = true;
    },
    // 确定查询是否开通短信服务
    addNoteSearch() {
      let params = {
        parentId: this.form.id,
      };
      let res = userSmsFindOne(params);
      res.then((res) => {
        Message.success(res.data.message);
        if (res.data.data != null) {
          // 编辑
          this.mid = res.data.data.id;
          this.formss = res.data.data;
          this.updateNoteVisible = true;
        } else {
          // 添加
          this.addNoteVisible = true;
        }
        this.addNoteSVisible = false;
      });
    },

    //确认上传备注
    causeSubmit() {
      let params = {
        companyId: this.companyRemarksId,
        remarke: this.custom_stopReason,
        status: this.custom_status == 1 ? 2 : 1,
      };
      let res = locking(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success("修改成功");
          this.causeVisble = false;
          this.getList();
        } else {
          Message.success(res.data.message);
        }
      });
    },
    // 历史充值记录弹出框事件
    mqyspyy() {
      let params = {
        page: this.rechargeParameter.pageIndex,
        remarks: "充值金额",
        id: this.forms.id,
      };
      let res = logUserList(params);
      res.then((res) => {
        let data = res.data.data;
        this.rechargeRecord = data;
        this.rechargeParameter.pageTotal = res.data.total;
      });
      this.drawer = true;
    },
    handleRechargeSizeChange(val) {
      this.mqyspyy();
      console.log(`每页 ${val} 条`);
    },
    handleRechargeCurrentChange(val) {
      this.rechargeParameter.pageIndex = val;
      this.mqyspyy();
    },
    //重置按钮
    reset() {
      this.seatRateValue = null;
      this.usernameSearch = null;
      this.realnameSearch = null;
      this.operators = null;
      this.grade = null;
      this.type = null;
      this.salesman = null;
      this.value = null;
      (this.forms_ip = []), (this.id = null), this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 展示
    async getList() {


      let params = {
        seatRate: this.seatRateValue,
        id: this.id,
        page: this.page,
        size: this.size,
        username: this.usernameSearch,
        realname: this.realnameSearch,
        operators: this.operators,
        salesman: this.salesman,
        grade: this.grade,
        chooseType: this.type,
        parentId: 0,
        status: this.value ? this.value : null,
        stopReason: this.stopReason,
      };
      // ip传
      let axbArr = [3, 4, 6, 7, 9, 11, 12];
      let hbArr = [15, 14, 8, 2, 5, 13, 1, 10, 17, 18];
      if (axbArr.includes(this.type * 1)) {
        params.ip = 0;
      } else if (hbArr.includes(this.type * 1)) {
        params.ip = 1;
      } else {
        console.log("type在axbArr和hbArr中都没有");
      }
      let res = await userFindlist(params);
      if (res.data.statusCode == null) {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;

        //循环每一个数据
        let ip0Arr = []
        let ip1Arr = []
        this.tableData.forEach((v) => {
          if (v.ip == 0) {
            ip0Arr.push(v.appid)
          } else {
            ip1Arr.push(v.appid)
          }
        })
        let resp0 = {}
        if (ip0Arr.length != 0) {
          resp0 = await userFindAppidbytype({
            ip: 0,
            listAppid: ip0Arr
          });
          resp0 = res.data.data
        }
        let resp1 = {}
        if (ip1Arr.length != 0) {
          res = await userFindAppidbytype({
            ip: 1,
            listAppid: ip1Arr
          });
          resp1 = res.data.data
        }

        console.log("ip0Arr", resp0);
        console.log("ip1Arr", resp1);
        const newObj = Object.assign(resp1, resp0)

        Object.keys(newObj).forEach((key) => {
          this.tableData.forEach((v) => {
            if (v.appid == key + '') {
              v.type = newObj[key]
            }
          })
        })



        // for (var i = 0; i < this.tableData.length; i++) {
        //   let params = {
        //     ip: this.tableData[i].ip,
        //     appid: this.tableData[i].appid,
        //   };
        //   let index = i;
        //   let resp = await userFindAppidbytype(params);
        //   this.tableData[index].type = resp.data.data;
        // }
      } else {
        Message.error(res.data.message);
      }
    },
    //解锁/锁定
    lock(row) {
      this.editVisible = false;
      this.companyRemarksId = row.row.id;
      this.causeData = row.row.stopReason;
      this.custom_stopReason = null;
      this.custom_status = row.row.status;
      // this.$confirm("是否确认该操作?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      //   center: true,
      // })
      //   .then(() => {
      //     let params;
      //     if (row.row.status == 2) {
      //       params = {
      //         companyId: row.row.id,
      //         status: 1,
      //       };
      //     } else {
      //       params = {
      //         companyId: row.row.id,
      //         status: 2,
      //       };
      //     }
      //     let res = locking(params);
      //     res.then((res) => {
      // if (res.data.statusCode == "00000") {
      // Message.success(res.data.message);
      this.$nextTick(() => {
        this.causeVisble = true;
      });
      //   } else {
      //     Message.error(res.data.message);
      //     this.getList();
      //   }
      // });
      // })
      // .catch(() => {
      //   Message.info("已取消操作");
      // });
    },
    //点击添加按钮
    add() {
      (this.form.username = ""),
        (this.form.password = ""),
        (this.form.realname = ""),
        (this.form.ip = ""),
        (this.form.rate = ""),
        (this.form.operators = ""),
        (this.form.salesman = ""),
        (this.form.seatRate = 0),
        (this.form.seatRateW = ""),
        (this.form.appid = ""),
        (this.form.remark = ""),
        (this.form.seatCount = ""),
        (this.form.whole = ""),
        (this.addVisible = true);
    },
    //添加确定按钮
    saveAdd() {
      if (
        this.form.seatRate == " " ||
        this.form.rate == " " ||
        this.form.seatRateW == " " ||
        this.form.salesman == " " ||
        this.form.operators == " " ||
        this.form.remark == " "
      ) {
        Message.error("请输入客服人员、业务员及签约周期等必选项！");
      } else {
        let params = {
          username: this.form.username,
          password: this.form.password,
          realname: this.form.realname,
          ip: this.form.ip,
          rate: this.form.rate,
          operators: this.form.operators,
          salesman: this.form.salesman,
          seatRate: this.form.seatRate,
          seatRateW: this.form.seatRateW,
          appid: this.form.appid,
          remark: this.form.remark,
          seatCount: this.form.seatCount,
          whole: this.form.whole,
        };
        let res = userAdd(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
          } else {
            Message.error(res.data.message);
          }
        });
        this.addVisible = false;
      }
    },
    //编辑按钮
    update(row) {
      console.log(row.row);
      this.updateShow = true;
      this.forms.username = row.row.username;
      this.forms.appid = row.row.appid;
      // this.forms.password = row.row.password;
      this.forms.password = null;
      this.forms.realname = row.row.realname;
      this.forms.grade = row.row.grade;
      this.forms.remark = row.row.remark;
      this.forms.whole = row.row.whole;
      this.forms.seatCount = row.row.seatCount;
      this.forms.operators = row.row.operators;
      this.forms.salesman = row.row.salesman;
      this.forms.rate = row.row.rate;
      this.forms.seatRate = row.row.seatRate;
      this.forms.seatRateW = row.row.seatRateW;
      this.forms.id = row.row.id;
      this.forms.ip = row.row.ip;
      this.forms.seatMoney = row.row.seatMoney;
      this.companyType = 0
      // this.forms = row.row;
    },
    //编辑确认
    updateSubmit() {
      let params = {
        username: this.forms.username,
        appid: this.forms.appid,
        password: this.forms.password,
        realname: this.forms.realname,
        grade: this.forms.grade,
        remark: this.forms.remark, //签约周期
        whole: this.forms.whole,
        seatCount: this.forms.seatCount,
        operators: this.forms.operators,
        salesman: this.forms.salesman,
        rate: this.forms.rate,
        seatRate: this.forms.seatRate,
        seatRateW: this.forms.seatRateW,
        id: this.forms.id,
        ip: this.forms.ip,
      };
      this.$confirm(`确认要完成对企业 ${this.forms.realname} 的修改吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let res = await userUpdate(params);
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.updateShow = false;
          this.getList();
        } else {
          Message.error(res.data.message);
          this.updateShow = false;
          this.getList();
        }
      }).catch(() => {
        Message.info('已取消');
      });

    },
    //充值按钮
    recharge(row) {
      this.rechargeShow = true;
      this.forms.money = null;
      this.remarks = null;
      this.forms.id = row.row.id;
      this.forms.realname = row.row.realname;
      this.forms.oldMoney = row.row.money;
    },
    //充值确定按钮
    rechargeSubmit() {
      // if (!this.imgUrl) {
      //   Message.warning('充值时必须上传附件图片!');
      //   return false
      // }
      let params = {
        id: this.forms.id,
        realname: this.forms.realname,
        money: this.forms.money,
        oldMoney: this.forms.oldMoney,
        remarks: this.remarks,
        img: this.imgUrl,
        companyType: this.companyType,
      };
      this.updateShow = false;
      let res = userUpdate(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.getList();
        } else {
          Message.error(res.data.message);
          this.getList();
        }
      });
      this.$refs.uploadImg.clearFiles()
      this.imgUrl = ""
    },
  },
};
</script>

<style lang="less" scoped>
#creatCompany {
  .historyBox {
    /deep/ .el-drawer {
      padding: 20px !important;
    }
  }

  /deep/.edit-box {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;

    .el-button {
      margin-left: 0;
      margin-right: 16px;

      span {
        display: flex;
        justify-content: space-between;
        width: 170px;
      }
    }
  }

  .quit-btn {
    position: fixed;
    z-index: 4;
    top: 18px;
    right: 400px;
  }

  .add-rate {
    /deep/.el-form-item__label {
      width: 150px !important;
    }

    /deep/.el-textarea,
    /deep/.el-input {
      width: 90% !important;
    }
  }

  header {
    h1 {
      font-weight: normal;
    }

    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;

        .el-select {
          width: 100%;
        }

        .left-wrap {
          width: 20%;
          display: flex;
          align-items: center;

          span {
            margin: 10px;
            min-width: 60px;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }

  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }

  .recharge-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dblclick /deep/.el-dialog__header {
    padding: 0;
  }

  .refund {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    grid-gap: 10px;
  }

  .balance {
    display: flex;
    align-items: center;
  }
}

/deep/.el-drawer {
  width: 45% !important;

  .el-drawer__header {
    font-size: 18px;
  }

  .skbContent {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    padding: 10px 10px;
    box-sizing: border-box;

    .tclist {
      color: #606266;
      font-size: 16px;
      display: flex;
      padding-bottom: 14px;
      box-sizing: border-box;
    }

    .nullTitle {
      font-size: 20px;
      text-align: center;
      font-weight: bold;
      line-height: 400px;
    }
  }
}
</style>
