/**
 * company_renew
 * 
 */

import request from '@/utils/request'

// 
export const add_update = (data) => {
    return request({
        method: 'post',
        url: '/sysUserIpLimit/add-update',
        data
    })
}
// 
export const findOne = (data) => {
    return request({
        method: 'post',
        url: '/sysUserIpLimit/findOne',
        data
    })
}

